.guest-button {
  margin-bottom: 1rem !important;
}
.spinner {
  position: absolute;
  left: -81px;
  right: 0px;
  bottom: 201px;
  z-index: 999;
}
.after-payment {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
  height: 45vh;
}
.checkout {
  align-items: center;
  display: flex;
  flex-direction: column;
  &-sum {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  // width: 50%;
  margin: 0 auto;
  text-align: center;
  &-summary {
    width: 8em;
  }
  h4 {
    margin: 0;
    margin-top: 1rem;
  }

  button {
    margin: 0 auto;
  }

  .stripe-card {
    margin: 1rem 0;
  }

  .card-number {
    margin-top: 2rem;
  }

  .card-element {
    border: 1px solid grey;
    border-radius: 3px;
    padding: 10px;
    width: 55%;
    margin: 0 auto;
  }

  .saved-card-btn {
    margin-top: 1.5rem;
  }
}
.check-container {
  position: relative;
  flex-direction: column !important;
  height: 66vh;
  justify-content: space-evenly;
}
@media (min-width: 1024px) {
  .checkout {
    flex-direction: column;
  }
}
.form {
  width: 43rem;
}
