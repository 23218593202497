.cart-container {
  position: relative;
  margin-left: auto;
  cursor: pointer;
  i {
    width: 30px;
  }

  .cart-count {
    background-color: #779976fa;
    color: white;
    position: absolute;
    top: -13px;
    left: 17px;
    font-size: 15px;
    width: 20px;
    display: flex;
    align-items: center;
    height: 22px;
    justify-content: center;
    /* padding: 4
px
; */
    border-radius: 9px;
  }
}
