.main-container {
  margin-left: 0%;
  margin-right: 0%;
}
@media (min-width: 1024px) {
  .main-container {
    margin-left: 5%;
    margin-right: 5%;
    position: relative;
    min-height: 70vh;
  }
}
