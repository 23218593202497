// .hero-image {
//   background: url('../../assets/1.jpg') center/cover no-repeat;
// }
.carousel {
  .img-cont {
    height: 11rem;
  }
  margin-left: 5rem;
  margin-right: 5rem;
}
.carousel-control-prev-icon {
  display: none;
}
.carousel-control-next-icon {
  display: none;
}
.hero-title {
  text-align: center;
  font-size: 2rem;
}

@media (min-width: 1024px) {
  .carousel {
    .img-cont {
      height: 33rem;
    }
  }
  .hero-image {
    font-size: 2.5rem;
  }
}

.shop-now-btn {
  text-align: center;
}
.extracon {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}
@media (max-width: 600px) {
  .carousel {
    margin-left: 0rem;
    margin-right: 0rem;
  }
}
