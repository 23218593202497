.nav-menu {
  display: flex;
  padding: 1rem;
  align-items: center;
  a {
    color: black;
    text-decoration: none;
    &:focus,
    &:hover,
    &:visited {
      color: black;
    }
  }

  .logo {
    font-size: 1.5rem;
  }

  ul {
    list-style-type: none;
    margin: 0;
    margin-left: auto;

    li {
      display: inline-block;
      margin: 0 1rem;
      cursor: pointer;
    }
  }
}
.t-logo {
  width: 100%;
  height: 100%;
}
.cart-container {
  cursor: pointer;
  margin-top: 18px;
}
.nav-ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.logo-container {
  width: 10rem;
}
.pc-view-false {
  display: none;
}
.pc-view {
  margin-top: 12px !important;
}
@media (max-width: 600px) {
  li {
    padding: 1rem;
  }
  .mobile-menu-container {
    border: 1px solid black;
    a {
      text-decoration: none;
      &:focus,
      &:hover,
      &:visited {
        color: black;
      }
    }
    ul {
      list-style-type: none;
    }
  }
  .pc-view-false {
    // margin-top: 12px !important;
    display: block;
  }
  .pc-view {
    display: none !important;
  }
  .cart-container,
  .menu-icon {
    margin-top: 3rem !important;
    // margin-top: 12px !important;
    cursor: pointer;
    margin: 2rem;
  }
  .nav-menu {
    ul {
      margin: 0;
      padding: 0;
    }
    li {
      margin: 0 !important;
      padding: 0 !important;
    }
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    width: 100%;
  }
}
