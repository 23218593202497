.featured-product {
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 1rem;
  margin-left: 1rem 0;
}
// @media (min-width: 1024px) {
//   .featured-product {
//     height: 492px;
//     width: 491px;
//   }
// }
