.us-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-left: 7%;
  min-height: 38vh;
  height: 100%;
  margin-right: 7%;
}
