.footer {
  position: absolute;
  // bottom: 0px;
  height: 2.5rem;
  width: 100%;
  margin: 4rem auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  a {
    text-decoration: none;
    img {
      cursor: pointer;
      max-width: 199px;
    }
  }
}
