h1,
.empty-cart {
  text-align: center;
}
.is-white {
  border: 1px solid black !important;
}
.total {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.checkout {
  flex-direction: row !important;
}
.cart-item {
  text-align: center;
  border: 1px solid #ccc;
  margin: 2rem;
  padding: 1rem;

  .item-image {
    img {
      width: 100%;
    }
  }

  .name-price {
    h4 {
      margin: 0;
    }
    p {
      font-size: 1rem;
      font-weight: normal;
    }
  }

  .btns-container {
    button {
      border: none;
      padding: 0.5rem 1rem;
      cursor: pointer;
      font-size: 10px;
      margin-right: 5px;
    }

    .btn-increase {
      color: #fff;
      background-color: #1a1a1a;
    }

    .btn-decrease,
    .btn-trash {
      color: #fff;
      background-color: #d9534f;
    }
  }

  .total-container {
    margin: 2rem;
    text-align: center;
  }
}

@media (min-width: 1024px) {
  .checkout {
    flex-direction: column !important;
  }
  .is-white {
    margin-top: 1rem !important;
  }
  .total {
    flex-direction: column;
  }
  .cart-page {
    img {
      width: 340px !important;
    }
    display: flex;
    justify-content: center;

    .total-container {
      text-align: center;
    }
  }
  .cart-item {
    display: flex;
    justify-content: space-between;
    div {
      margin: 1rem;
    }
    .name-price {
      width: 200px;
    }
  }
}
